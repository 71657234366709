
<script>
    export let css = "";
</script>


<tr>
    <td class="align-middle text-sm pl-1 pb-1">
        <div class="{css}">
            <slot/>
        </div>
    </td>
</tr>
