

export interface IUser {
    id: number;
    email: string;
    name: string;
    surname: string;
    phone: string;
    phone_whatsapp: string;
    phone_alternative: string | null;
    skype_name: string | null;
    emergency_contact_name: string;
    emergency_contact_phone: string;
    nationality_id: number;
    photo_url: string;
    id_document_url: string | null;
    id_number: string | null;
    passport_number: string | null;
    date_of_birth: string;
    gender: string;
    home_language_id: number;
    disabled: number;
    referral_type_id: number;
    method_of_communication_id: number | null;
    created_at: string;
    updated_at: string;
    last_logged_in_at: string;
    old_system_id: string | null;
    original_updated_at: IDateTimezone;
    role: string;
    distance: string | null;
    tutor_regions: string;
    gender_value: string;
    gender_icon: string;
    subjects: any[];
    tertiary_educations: any[];
    own_transport: string;
    own_transport_bool: boolean;
    availability: string;
    availability_short: string;
    availability_bool: boolean;
    tutor_profile: ITutorProfile;
    subjects_tutors: any[];
    tutor_tertiary_educations: any[];
    tutorStatus: ITutorStatus;
    tutorMatricSubjects: ITutorMatricSubjects[];
    mileStoneHoursTutored: number;
    hoursTutored: number;
    semesterAddress: string;
    syllabusName: string;
    syllabusGrading: string;
    debug: boolean;
}

export interface IDateTimezone {
    date: string;
    timezone_type: number;
    timezone: string;
}

export interface ITutorProfile {
    id: number;
    user_id: number;
    own_transport: number;
    use_own_transport: number;
    matric_certificate_url: string | null;
    cv_url: string | null;
    strong_personality_trait_1: string;
    strong_personality_trait_2: string;
    strong_personality_trait_3: string;
    personal_achievements: string;
    other_interests: string;
    smokes_option_id: number;
    drinks_option_id: number;
    drugs: number;
    treated_for_addiction: number;
    medical_condition: number;
    medical_condition_description: string | null;
    criminal_record: number;
    criminal_record_description: string | null;
    past_experience_tutor: number;
    experience_with_other_special_needs: number;
    year_matriculated: number;
    syllabus_id: number;
    high_school_attended: string;
    no_tertiary_studies: number;
    mini_bio: string;
    staff_bio: string;
    screening_notes: string;
    interview_notes: string;
    additional_comments: string;
    next_checklist_item_id: string | null;
    interview_date: string | null;
    confirmed_interview_date: string | null;
    rate_1_00_hours: string | null;
    rate_1_30_hours: string | null;
    rate_2_00_hours: string | null;
    travel_rate: string | null;
    is_available_mornings: number;
    is_available_afternoons: number;
    is_available_evenings: number;
    is_available_weekends: number;
    availability_details: string | null;
    home_address_is_same: number;
    star_rating: number;
    tutor_status_id: number;
    created_at: string;
    updated_at: string;
    complete_profile_reminder_sent_at: string;
    complete_profile_reminder_7day_sent_at: string;
    tutor_matric_subjects: ITutorMatricSubject[];
}

export interface ITutorMatricSubject {
    id: number;
    tutor_profile_id: number;
    subject_id: number;
    subject_level_id: number;
    symbol: string;
    percentage: number;
    created_at: string;
    updated_at: string;
    subject: ISubject;
}

export interface ISubject {
    id: number;
    label: string;
    created_at: string;
    updated_at: string;
}

export interface ITutorStatus {
    id: number;
    label: string;
    slug: string;
    created_at: string;
    updated_at: string;
    order: number;
}

export interface ITutorMatricSubjects {
    subject: string;
    symbol: string;
    percentage: number;
}

import TutorCard from "./tutor_card.svelte";

const inflateElement = (element: any) => {
    // get data attributes named data-props
    console.log(element.getAttribute('inflated'))

    if (element.getAttribute('inflated') === 'true') return;

    element.setAttribute('inflated', 'true')

    let { props: data, ...others } = element.dataset;
    data = data ? JSON.parse(data) : {};
    let props: IUser = Object.keys(others).map((key) => {

        try {
            return {
                [key]: JSON.parse(others[key])
            };
        } catch (e) {
            return {
                [key]: others[key]
            };
        }
    }).reduce((acc, curr) => {
        return Object.assign(acc, curr);
    }, data);

    props = {
        ...props,
        // debug: true
    };

    const card = new TutorCard({
        target: element,
        props,
    });
};

document.querySelectorAll(".tutor-card")
    .forEach(inflateElement);



// Create an observer instance
let observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
        // If this mutation represents added nodes...
        if (mutation.addedNodes) {
            mutation.addedNodes.forEach((node: any) => {



                // ...and the added node is an element...
                if (node.nodeType === Node.ELEMENT_NODE) {

                    function hasClass(node) {
                        return 'classList' in node && node.classList.contains("tutor-card") || node.querySelector(".tutor-card")
                    }

                    if (hasClass(node)) {
                        var elements = document.querySelectorAll(".tutor-card[inflated='false']");
                        elements.forEach(inflateElement);
                    }
                }
            });
        }
    });
});

// Set up the observer to watch the entire document and subtree for childList changes
observer.observe(document, { childList: true, subtree: true });
