<script>
    export let debug=false;
	import IconText from "./tutor_card.component/icon-text.svelte";
	import Icon from "./shared/Icon.svelte"
	import Text from "./tutor_card.component/text.svelte";
	import CardItem from "./tutor_card.component/card-item.svelte";
	import GradeDisplay from "./tutor_card.component/grade-display.svelte";
	import CardRow from "./shared/cards/card-row.svelte"
	import Page from "./shared/page.svelte";
	import dateformat from "dateformat";
	export let date_format ="dd/mm/yyyy h:MM TT";
	export let buttons = [];


	export let client = null;

	export let id=0;

    export let theme = "#000";
	
	
	
	
	export let tutorStatus;
	export let tutorOnline;
	export let name;
	export let surname;
	export let own_transport_bool;
	export let mileStoneHoursTutored;
	export let hoursTutored;
	export let phone;
	export let email;
	export let availability;
	export let semesterAddress;
	export let travel_distance;
	export let distance;


	const tutor_name = `${name} ${surname}`
	export let photo_url;
	// export let distance=-1;
	
	console.log(buttons);


	// Date Information
	export let created_at;
	export let updated_at;
	export let last_logged_in_at;
	const last_activity = new Date(last_logged_in_at || updated_at || created_at || "2000-01-01");





	// Education
	export let tertiary_educations = [];
	export let syllabusName;
	export let syllabusGrading;
	export let tutorMatricSubjects=[];

	export let hasCar = own_transport_bool;
	export let gender_value = 'm';
	export let tutorPreference = "N/A";

	export let tutorSubjectPreferences = [];


	function smooth(date,format){

		try {
			return dateformat(date, format);
		} catch (err) {
			return "N/a";
		}

	}

	let enableDebug = false;


	function toggleDebug(event){
		event.preventDefault();
		enableDebug = !enableDebug
	}

	$: genderDisplay = gender_value;
	$: genderColor = gender_value=='m'? 'text-blue-600':'text-pink-600'
	$: formattedDistance = distance ? distance.toFixed(2) : null;
	$: model_date = last_activity ? smooth(last_activity, date_format): "N/a";	
	$: carColor = hasCar ? "text-green-600" : "text-red-500";
	$: carModel = hasCar ? "directions_car" : "car_crash";
	$: isLocalhost = getIsLocalHost();



	function toggle(event){
		const animationContainer = event.target.closest('.animation-container');
		const animationTarget = animationContainer.querySelector('.animation-target');
		const dropdown = event.target.closest('.dropdown')
		const isExpanded = getComputedStyle(animationTarget).height !== '0px';


		setTimeout(() => {
			if(isExpanded){
				animationTarget.style.overflow = 'visible';
			}else {
				animationTarget.style.overflow = 'hidden';
			}
		}, 500);


		if(isExpanded){
			animationTarget.style.height = '0px';
			dropdown.classList.remove('spin');
		}else {
			dropdown.classList.add('spin');
			animationTarget.style.height = animationTarget.scrollHeight*1.2 + 'px';
		}
	}

	function gradeLength(grades){
		return (grades||[]).join(",").length > 50
	}

	async function copyToClipboard() {
		try {
		await navigator.clipboard.writeText(JSON.stringify($$props));
		console.log('Text copied to clipboard');
		} catch (err) {
		console.log('Failed to copy text: ', err);
		}
	}

	function getIsLocalHost(){
		const localhostRegex = /^(localhost|[\w.-]*localhost[:\d]*)$/i;
		return localhostRegex.test(window.location.hostname)
	}

</script>

<style>
	main {
		display: block;
	}

	.container{
		margin:auto;
	}

	.page {
		max-width: 1500px;
		margin: auto;
	}	

	.animation-container {
		overflow: hidden;
	}

	.animation-container .animation-target {
		transition: height 0.2s ease-in-out;
	}

	.animation-container .animation-target {
		height: 0px;
	}

	.dropdown{
		animation: transform 0.2s ease-in-out;
		transform: rotate(0deg);
		transition: transform 0.2s;
	}


	:global(.dropdown.spin){
		transform: rotate(180deg) !important;
	}
</style>

<main>
	

	{#if debug || enableDebug }
		<Page>
			<div class="ml-auto">
				<button class="p-2 bg-slate-500" on:click={copyToClipboard}>Copy to clipboard</button>
				<button class="text-black bg-white rounded p-1 {enableDebug?'border-red-600 border-1':''}" on:click={toggleDebug}>
					debug
				</button>
			</div>
				
			<pre class="pb-4">
				{JSON.stringify($$props, null, 2)}
			</pre>
		</Page>

		{:else}
		<div class="bg-grey-200 w-full">
			<Page class="page">
				<div class="container">
					<div class="flex flex-col shadow-lg rounded-lg border border-slate-600 animation-container bg-white">
						<a href="/tutors/edit/{id}" target="_blank">
							<div class="card-header flex flex-row p-1 items-end rounded-t-lg justify-between md:justify-around items-center" style="background-color:{theme}">
								<div class="w-8 h-8">
									{#if photo_url}
										<img src="{photo_url}}" alt="Avatar" class="w-8 h-8 rounded">
									{:else}
										<div class="w-8 h-8 bg-white rounded-full flex items-center justify-center">
											<span class="material-icons text-slate-600">person</span>
										</div>
									{/if}
								</div>
								<div class="flex flex-col items-end md:flex-row md:justify-between  md:items-baseline flex-grow justify-end text-sm text-base md:text-2xl">
									<div class="text-white font-bold  md:pl-5 text-right sm:text-left">{tutor_name} <span class="block sm:inline">({tutorStatus.label})</span></div>
								</div>
								{#if client}
									<a href="/link/confirm?assignClientId={client.id}&assignTutorId={id}">
										<div class="p-5 bg-orange-500 text-white text-sm">
											Assign
										</div>
									</a>
								{/if}
								{#if isLocalhost}
									<button class="text-black bg-white rounded p-1 {enableDebug?'border-red-600':''}" on:click={toggleDebug}>
										debug
									</button>
								{/if}
							</div>
						</a>
						<div class="grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-1 rounded-b-lg m-auto w-full md:w-11/12 py-1">
								
								<CardItem>
										<CardRow>
											<IconText icon="schedule" css="text-slate-700">
												{model_date}
											</IconText>
										</CardRow>

										<CardRow>
											<IconText icon="{carModel}" css="{carColor}">
												{#if distance}
													{formattedDistance} km
												{/if}
												{#if tutorPreference!='N/A' || travel_distance} 
													{#if tutorPreference.toLowerCase() == "online only"}
														<span class="text-xs text-slate-400 lowercase">({tutorPreference})</span>
													{:else}
														<span class="text-xs lowercase">(Up To {travel_distance}km)</span> 
													{/if}
												{/if}
											</IconText>
										</CardRow>

										{#if tutorOnline}
											<CardRow>
												<IconText icon="wifi">
													{#if tutorOnline} Online {#if travel_distance==0} only {/if} {:else} Offline {/if}
												</IconText>
											</CardRow>
										{/if}
								</CardItem>

								<CardItem css="collapse md:visible bg-white">
									<CardRow>
										<IconText icon="phone" css="text-slate-600">
											{phone}
										</IconText>
									</CardRow>
									<CardRow>
										<IconText icon="email" css="text-orange-300 ">
											{email}
										</IconText>
									</CardRow>

									<CardRow>
										<td colspan="2">
											<Icon icon="hourglass_bottom" css="text-gray-400"/> {hoursTutored} hr
											<Icon icon="workspace_premium" css="text-yellow-500" /> {mileStoneHoursTutored} hr
										</td>
									</CardRow>
									<CardRow>
										<IconText icon="map" css="text-red-500">
											{semesterAddress}
										</IconText>
									</CardRow>
								</CardItem>
								
								<CardItem css="md:hidden bg-gray-100 rounded p-1 bg-white">
									<CardRow>
										<td>
											<IconText icon="hourglass_bottom" css="text-gray-400">
												{hoursTutored} hr
											</IconText>
											<IconText icon="workspace_premium" css="text-yellow-500">
												{mileStoneHoursTutored} hr
											</IconText>
										</td>
									</CardRow>
									<CardRow>
										<td class="text-left md:text-center"><span class="material-icons text-red-500">map</span></td>
										<td class="align-baseline font-bold text-sm">{semesterAddress}</td>
									</CardRow>
								</CardItem>
								

								<CardItem title="{syllabusName}">
									{#each tutorMatricSubjects as subject,index}
										<Text css="{index%2?'bg-slate-100':''}">
											<GradeDisplay 
												grading="{syllabusGrading}" 
												subject="{subject.subject}" 
												grade="{subject.percentage}"
											/>
											
										</Text>
									{:else}
										<Text css="text-red-600">No Subjects</Text>
									{/each}
								</CardItem>

								<CardItem title="Post-Secondary Education">
									{#each tertiary_educations as postSchool,index}
										<Text css="{index%2?'bg-slate-100':''}">
											{@html postSchool}
										</Text>
									{:else}
										<Text css="text-red-600">
											No Post-Secondary Education
										</Text>
									{/each}
								</CardItem>
								
						</div>
						<!-- Divider -->
						<div class="flex flex-row justify-end pr-1 text-slate-800">
							<span on:click={toggle} on:keydown={toggle} class="dropdown material-icons cursor-pointer select-none" >expand_circle_down</span>
						</div>
						<!-- Second Section -->
						<div class="animation-target">
							<div class="grid grid-cols-1 md:grid-cols-2 p-3 grid-flow-row gap-4 rounded-b-lg m-auto md:w-11/12">

								<CardItem title="Subject Preferences">
									{#each tutorSubjectPreferences as subject,index}
										<Text  css="{index%2?'bg-slate-100':''} flex flex-wrap items-center">
											<span class="flex-shrink-0 pr-1">{subject.label}</span> 
											{#if subject.grades.length==0}
												<span/>
											{:else}
												<span class="text-xs italic min-w-0">({(subject.grades||[]).join(", ")})</span>
												{#if subject.experience}
													<span class="pl-2" title="Has Experience">
														<Icon icon="library_books" css="cursor-pointer"/>
													</span>
												{/if}
											{/if}
										</Text>
									{:else}
										<Text>No Subjects</Text>
									{/each}
								</CardItem>
								

								<CardItem title="Additional Information">
									<CardRow>
										<IconText icon="schedule">
											{availability}
										</IconText>
									</CardRow>
									
								</CardItem>				
							</div>
						</div>
					</div>
				</div>
			</Page>
		</div>
	{/if}
</main>