
<script>
    export let grading;
    export let grade;
    export let subject;


    $: gradeCss = grading == "Percentage" ? 
            parseInt(grade) >= 80 ? 'text-green-600' :
            parseInt(grade) > 60 ? 'text-blue-600' : 'text-red-600'
            : 'text-slate-700';



</script>


<span>
    {subject} <span class="{gradeCss}">({grade}{#if grading=="Percentage"}%{/if})</span>
</span>